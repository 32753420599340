<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
              <custom-alert v-if="displayAlert==true" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
                <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
                    <div class="card">
                        <div class="card-body table-responsive table-head pt-2 hvertical">
                            <div class="row">
                                <div class="col-md-8 text-left px-0 bor-bot pb-3">
                                    <h1 class="m-0 text-dark pt-2 text-left px-0">{{ this.$route.params.id ? 'Edit' : 'Add' }} FAQ</h1>
                                </div>
                                <div class="col-md-4 bor-bot pr-0">
                                    <div class="text-right pb-3 pt-1">
                                        <ol class="breadcrumb float-sm-right pr-0">
                                            <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                                            <li class="breadcrumb-item"><Breadcrumbs/></li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
<!--                            <div class="row">-->
<!--                                <div class="col-md-12 text-center adfaqcont">-->
<!--                                    <div>-->
<!--                                        <img src="/dist/faq-icon.svg"/>-->
<!--                                        <h3>Currently you don’t have any FAQ</h3>-->
<!--                                        <button type="button" v-b-modal.modal-faqcenter class="btn btn darkblubtn btn-outline-primary btn-outline-primary">Add FAQ</button>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
                          <div>
                            <form ref="form" @submit.stop.prevent="handleSubmit">
                              <div class="row">
                                <div class="col-md-2 mt-3 text-right">
                                  <label for="question">Question:</label>
                                </div>
                                <div class="col-md-10 mt-3">
                                  <b-form-input v-model="form.question"  @keydown="errors.clear('question')" :class="errors.has('question') ? 'is-invalid' : ''"></b-form-input>
                                  <b-form-invalid-feedback
                                      :state="!errors.has('question')"
                                  >
                                    {{ errors.get("question") }}</b-form-invalid-feedback>
                                </div>
                                <div class="col-md-2 mt-3 text-right">
                                  <label for="answer">Answer:</label>
                                </div>
                                <div class="col-md-10 mt-3">
                                  <ckeditor v-model="form.answer" id="answer" :class="errors.has('answer') ? 'is-invalid' : ''"></ckeditor>
                                  <b-form-invalid-feedback
                                      :state="!errors.has('answer')"
                                  >
                                    {{ errors.get("answer") }}</b-form-invalid-feedback>
                                </div>
                                <div class="col-md-2 mt-3 text-right">
                                      <label>Section</label>
                                </div>
                                <div class="col-md-10 mt-3">
                                      <select v-model="form.section" class="form-control">
                                        <option v-for="(item, index) in sections" :key="'coupon-type-'+index" :value="item.id">{{ item.section_name }}</option>
                                      </select>
                                      <b-form-invalid-feedback
                                          :state="!errors.has('section')"
                                      >
                                        {{ errors.get("section") }}</b-form-invalid-feedback>
                                </div>
                                <div class="col-md-2 mt-3 text-right">
                                      <label>Priority Order</label>
                                    </div>
                                <div class="col-md-10 mt-3">
                                      <input class="form-control" v-model="form.priority_order" maxlength="3" @keypress="isNumber($event)">
                                      <b-form-invalid-feedback
                                          :state="!errors.has('priority_order')"
                                      >
                                        {{ errors.get("priority_order") }}</b-form-invalid-feedback>
                                    </div>
                                <div class="col-md-2 mt-3 text-right">
                                  <label>Status:</label>
                                </div>
                                <div class="col-md-10 mt-3">
                                  <select v-model="form.status" class="form-control">
                                    <option value="">--Select--</option>
                                    <option value="1">Active</option>
                                    <option value="0">In Active</option>
                                  </select>
                                  <b-form-invalid-feedback
                                      :state="!errors.has('status')"
                                  >
                                    {{ errors.get("status") }}</b-form-invalid-feedback>
                                </div>
                                <div class="col-md-12 text-right mt-3">
                                  <button type="button" @click="faqList()" class="btn btn-secondary mr-3">Close</button>
                                  <button type="submit" :disabled="formSubmit" class="btn btn-primary"><i class="fas" :class="[ formSubmit ? 'fa-circle-notch fa-spin' : '' ]"></i>Save</button>
                                </div>

                                <!--                    <div class="col-md-12 text-right mt-3">-->
                                <!--                      <button type="submit" class="btn btn-secondary mr-3">Close</button>-->
                                <!--                      <button type="submit" class="btn btn-primary">Save</button>-->
                                <!--                    </div>-->
                              </div>
                            </form>
                          </div>

                        </div>

                    </div>
                    </section>
              </div>
        </div>
    </section>
</template>

<script>

import CKEditor from 'ckeditor4-vue';
import Vue from "vue";
import axios from "axios";
import Errors from "../../Errors";
import CustomAlert from "../CustomAlert";
  export default {
    name:"Add FAQ",
    components:{
      CustomAlert
    },
    data() {
      Vue.use( CKEditor );
        new Vue( {
        } );
      return {
        errors: new Errors(),
        form: {
          question: "",
          answer: "",
          section: "",
          priority_order: "",
          status: "",
        },
        alertMessage: "",
        displayAlert: false,
        sections:[],
        formSubmit:false,
      }
    },
    methods:{
      faqList()
      {
        this.$router.push({ path: '/admin/faq-list'});
      },
      loadsection()
      {
        axios.get(this.basePath + "api/faq/section", {headers: this.adminHeaders})
            .then((response) => {
              if (response.data.status == 'success') {
                this.sections = response.data.data;
              }
            }).catch((error) => {
          console.log(error);
        });
      },
      handleSubmit() {
        let url = process.env.VUE_APP_URL + "api/faq/list";

        if (this.$route.params.id) {
          url = process.env.VUE_APP_URL + "api/faq/list/" + this.$route.params.id;
        }

        axios.post(url, this.form, {headers: this.adminHeaders})
            .then((response) => {
              if (response.data.status == 'success') {
                var message = this.$route.params.id ? "saved" : "created";
                //alert("Section has been " + message + ".");
                this.displayAlert = true;
                this.alertMessage = "FAQ has been " + message + ".";
                setTimeout(() => {
                  this.$router.push({ path: '/admin/faq-list'});
                }, 2000);
              }
            }).catch((error) => {
            this.onFail(error.response.data.errors);
        });
      },
      loadfaq(id)
      {
        axios.get(this.basePath + "api/faq/list/"+ id, {headers: this.adminHeaders})
            .then((response) => {
              if (response.data.status == 'success') {
                this.form = response.data.data;
              }
            }).catch((error) => {
          this.onFail(error.response.data.errors);
        });
      },
      resetAlert(){
        this.displayAlert = false;
      },
      onFail(errors) {
        this.errors.record(errors);
      },
    },
    mounted() {
      this.loadsection();
      if(this.$route.params.id)
      {
        this.loadfaq(this.$route.params.id);
      }
    }
  }
</script>